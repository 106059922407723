import React, { useContext } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

// Components
import { Box } from '../Box'
import { Grid } from '../Grid'
import { NavBar } from '../NavBar'

// Stores
import { UserStoreContext } from '../../stores/UserStore'
import { UnreadMessagesBanner } from '../UnreadMessagesBanner'

/**
 * PrivateRoute
 */
const PrivateRoute = observer(({ component: Component, roles, children, ...rest }) => {
  const { isAuthenticated } = useContext(UserStoreContext)
  const [isOpen, setIsOpen] = React.useState(true)
  const location = useLocation()

  // User does not have an auth token - redirect to Login
  if (!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
    )
  }

  const toggleWidth = payload => {
    setIsOpen(payload)
  }

  // Base case - render the requested component
  return (
    <Grid columns={[isOpen ? '245px' : '50px', 'auto']} gap="none" style={{ height: '100vh' }}>
      <NavBar toggleButton={toggleWidth} />
      <Box fill style={{ height: '100vh', overflow: 'scroll' }} overflow="scroll">
        {/* <UnreadMessagesBanner currentLocation={location} /> */}
        <Route {...rest} render={props => <Component {...props} />}>
          {children}
        </Route>
      </Box>
    </Grid>
  )
})

export default PrivateRoute
