import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import { Header as GrommetHeader, Image, Collapsible } from 'grommet'
import { Tooltip } from 'react-tooltip'
import {
  User,
  Previous,
  Next,
  Home,
  Checkmark,
  Stakeholder,
  UserAdmin,
  Group,
  Map,
  PieChart,
  Power,
} from 'grommet-icons'
import styled from 'styled-components'

// Components
import { AppVersion } from '../AppVersion'
import { Box } from '../Box'
import { Button } from '../Button'
import { Header } from '../Header'
import { Nav } from '../Nav'
import { Text } from '../Text'
import { Restricted } from '../Restricted'

// Utils, Services & Messages
import logo from '../../images/logo.png'
import messages from './NavBar.messages'
import colors from '../../utils/colors'
import { Flag } from '../../utils/flags'

// Stores
import { RootStoreContext } from '../../stores/RootStore'
import { UserStoreContext } from '../../stores/UserStore'
import { logout } from '../../services/user.service'

/**
 * NavBar
 */
const NavBar = withRouter(({ history, toggleButton }) => {
  const { user: userStore, clearStore } = useContext(RootStoreContext)
  const { isDistributor } = useContext(UserStoreContext)
  const [isNavOpen, setIsNavOpen] = useState(true)
  const location = useLocation()

  return (
    <Box direction="row" fill="vertical">
      {/* Toggle Button */}

      {/* Collapsible Navigation */}
      <Collapsible direction="horizontal" open={isNavOpen}>
        <Nav direction="column" fill="vertical" background={colors.primary} gap="none">
          <GrommetHeader alignSelf="center" margin={{ top: 'large' }}>
            <Box
              style={{ height: '70px', width: '160px', alignItem: 'center', marginLeft: '8px' }}
            >
              <Image fit="contain" src={logo} />
            </Box>

            <Button
              plain
              color="transparent"
              onClick={() => {
                history.push({
                  pathname: '/profile',
                  state: { background: location },
                })
              }}
            >
              <User color="accent-2" size="22px" />
            </Button>
          </GrommetHeader>

          <Box flex="grow" justify="start">
            <NavItem to="/dashboard">
              <Header level={4} margin="none">
                <FormattedMessage {...messages.home} />
              </Header>
            </NavItem>

            <NavItem to="/checkinreview">
              <Header level={4} margin="none">
                <FormattedMessage {...messages.vcheckreview} />
              </Header>
            </NavItem>

            {isDistributor && (
              <NavItem to="/agencyinfo">
                <Header level={4} margin="none">
                  <FormattedMessage {...messages.agencyinfo} />
                </Header>
              </NavItem>
            )}

            <Restricted allowedRole="isAgentAdmin">
              <NavItem to="/agents">
                <Header level={4} margin="none">
                  <FormattedMessage {...messages.agents} />
                </Header>
              </NavItem>
            </Restricted>

            <NavItem to="/clients">
              <Header level={4} margin="none">
                <FormattedMessage {...messages.clients} />
              </Header>
            </NavItem>

            <NavItem to="/zones">
              <Header level={4} margin="none">
                <FormattedMessage {...messages.zones} />
              </Header>
            </NavItem>

            <Flag name={['pages', 'reports']}>
              <NavItem to="/report">
                <Header level={4} margin="none">
                  <FormattedMessage {...messages.reports} />
                </Header>
              </NavItem>
            </Flag>

            <Footer direction="row" align="center" justify="between">
              <LogoutLink
                onClick={() => logout(userStore.refreshToken, clearStore)}
                margin="medium"
                size="small"
                underline
                weight="bold"
              >
                Logout
              </LogoutLink>
              <AppVersion />
            </Footer>
          </Box>
        </Nav>
      </Collapsible>
      <Box
        background={colors.primary}
        justify="start"
        width={isNavOpen ? '30px' : '50px'}
        pad={{ horizontal: 'small' }}
        align="center"
      >
        <Button
          style={{ backgroundColor: 'transparent' }}
          icon={
            isNavOpen ? <Previous size="20px" color="white" /> : <Next size="20px" color="white" />
          }
          onClick={() => {
            toggleButton(!isNavOpen)
            setIsNavOpen(!isNavOpen)
          }}
          plain
        />
        {!isNavOpen && (
          <Box direction="column" align="center" pad="small" height="100%">
            <Button
              style={{ paddingLeft: 5, paddingRight: 5 }}
              plain
              color="transparent"
              onClick={() => {
                history.push({
                  pathname: '/profile',
                  state: { background: location },
                })
              }}
            >
              <User color="accent-2" size="22px" />
            </Button>
            <NavItem
              to="/dashboard"
              style={{ paddingLeft: 5, paddingRight: 5 }}
              data-tooltip-id="dashboard-tooltip"
              data-tooltip-content="Dashboard"
            >
              <Home size="20px" color="white" />
            </NavItem>
            <Tooltip id="dashboard-tooltip" style={toolTipStyle} />
            <NavItem
              to="/checkinreview"
              style={{ paddingLeft: 5, paddingRight: 5 }}
              data-tooltip-id="vcheck-tooltip"
              data-tooltip-content="Check-In Review"
            >
              <Checkmark size="20px" color="white" />
            </NavItem>
            <Tooltip id="vcheck-tooltip" style={toolTipStyle} />
            {isDistributor && (
              <>
                <NavItem
                  to="/agencyinfo"
                  style={{ paddingLeft: 5, paddingRight: 5 }}
                  data-tooltip-id="agency-info-tooltip"
                  data-tooltip-content="Agency Info"
                >
                  <UserAdmin size="20px" color="white" />
                </NavItem>
                <Tooltip id="agency-info-tooltip" style={toolTipStyle} />
              </>
            )}

            <Restricted allowedRole="isAgentAdmin">
              <NavItem
                to="/agents"
                style={{ paddingLeft: 5, paddingRight: 5 }}
                data-tooltip-id="agents-tooltip"
                data-tooltip-content="Agents"
              >
                <Stakeholder size="20px" color="white" />
              </NavItem>
              <Tooltip id="agents-tooltip" style={toolTipStyle} />
            </Restricted>

            <NavItem
              to="/clients"
              style={{ paddingLeft: 5, paddingRight: 5 }}
              data-tooltip-id="clients-tooltip"
              data-tooltip-content="Clients"
            >
              <Group size="20px" color="white" />
            </NavItem>
            <Tooltip id="clients-tooltip" style={toolTipStyle} />

            <NavItem
              to="/zones"
              style={{ paddingLeft: 5, paddingRight: 5 }}
              data-tooltip-id="zones-tooltip"
              data-tooltip-content="Global Zones"
            >
              <Map size="20px" color="white" />
            </NavItem>
            <Tooltip id="zones-tooltip" style={toolTipStyle} />

            <Flag name={['pages', 'reports']}>
              <NavItem
                to="/report"
                style={{ paddingLeft: 5, paddingRight: 5 }}
                data-tooltip-id="reports-tooltip"
                data-tooltip-content="Reports"
              >
                <PieChart size="20px" color="white" />
              </NavItem>
              <Tooltip id="reports-tooltip" style={toolTipStyle} />
            </Flag>

            <LogoutLink
              onClick={() => logout(userStore.refreshToken, clearStore)}
              margin="medium"
              size="small"
              underline
              weight="bold"
              style={{ marginTop: '50px' }}
              data-tooltip-id="logout-tooltip"
              data-tooltip-content="Logout"
            >
              <Power size="20px" color="white" />
            </LogoutLink>
            <Tooltip id="logout-tooltip" style={toolTipStyle} />
          </Box>
        )}
      </Box>
    </Box>
  )
})

const toolTipStyle = {
  zIndex: 9999,
}

const NavItem = styled(NavLink)`
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 19px;
  font-weight: thin;
  padding: 1em;
  outline: 0;
  text-decoration: none;
  user-select: none;

  &.active {
    background: ${colors.darkerGreyBlue};
  }
`
const Footer = styled(Box)`
  bottom: 0;
  position: absolute;
  @media (max-width: 768px) {
    position: sticky;
    margin-top: 50px;
  }
`
const LogoutLink = styled(Text)`
  cursor: pointer;
`

export default NavBar
