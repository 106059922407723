/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useContext, useReducer, useRef, useState } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { useReactToPrint } from 'react-to-print'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { map as _map, throttle as _throttle } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import 'moment-timezone'
import { observer } from 'mobx-react'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { ClientAvatar } from 'components/ClientAvatar'
import { ClientVCheckTable } from 'components/ClientVCheckTable'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { Message } from 'components/Message'
import { PrintableClientVCheckPage } from 'components/PrintableClientVCheckPage'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { TextBlock } from 'components/TextBlock'
import { WizardSidebar } from 'components/WizardSidebar'
import { Layer } from 'components/Layer'
import { Select } from '../../components/Select'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'
import { initialPaginationConstants as initialPagination } from '../../utils/helpers'

// Utils, Services & Messages
import colors from '../../utils/colors'
import messages from './ClientVCheckPage.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { roleTypeOption } from '../../utils/helpers'
import { Stack } from '../../components/Stack'
import { getClientAvatarlUrl } from '../../services/agency.service'

const DOWNLOAD_STATES = {
  idle: 0,
  downloading: 1,
  downloaded: 2,
}

const sidebarInitialState = {
  id: null,
  completed: null,
  type: '',
  video: null,
  randomPhrase: [],
  accepted: null,
  reviewed_by: '',
  reviewed_on: '',
  notes: '',
  bac_result: ''
}

const options = [
  { value: 'All', label: 'All' },
  { value: 'vchecks', label: 'VChecks' },
  { value: 'bac_checks', label: 'BAC Checks' }
];

let perPageCount = initialPagination.rows
let currentPage = initialPagination.pageNumber

/**
 *
 * ClientVCheckPage
 *
 * This container holds all components needed for the Client VCheck Page
 * The user navigates to this page when they click on the VChecks dropdown
 * button on the Client Summary Page and select "View All VChecks"
 *
 * Timezone Notes:
 * There are three date times displayed on this page/sidebar
 *  - scheduled (displayed in client timezone because it is client-created)
 *  - completed (displayed in client timezone because it is client-created)
 *  - reviewed on (displayed in user timezone because it is user-created)
 *
 */
const ClientVCheckPage = observer(({ intl }) => {
  const { getAgencyById, getAgentsByAgency } = useContext(AgencyStoreContext)
  const {
    getClientById,
    getClientVCheckEventsById,
    updateClientVCheckEventById,
    distributorClientSelectedAgencyId,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)
  const ClientWizard = useContext(ClientWizardStoreContext)

  const [pageLoadError, showPageLoadError] = useState()
  const [pageLoading, setPageLoading] = useState()
  const [selectedClientTypes, setselectedClientTypes] = useState('All');

  const [videoLoading, setVideoLoading] = useState(false)
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(null)
  // boolean for showing sidebar & modal
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [downloadState, setDownloadState] = useState(DOWNLOAD_STATES.idle)
  const { message: deactivateError, showMessage: showDeactivateError } = useFlashMessage(null)

  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    {},
  )
  const [clientVChecks, setClientVChecks] = useState([])
  const [filteredClientVChecks, setFilteredClientVChecks] = useState([])
  const [agents, setAgents] = useState([])
  const [agencyName, setAgencyName] = useState('Agency')

  const [showOnTimeStatus, setShowOnTimeStatus] = React.useState(true)
  const [showLateStatus, setShowLateStatus] = React.useState(true)
  const [showMissedStatus, setShowMissedStatus] = React.useState(true)
  const [showGroupedStatus, setShowGroupedStatus] = React.useState(true)
  const [clientAvatarUrl, setClientAvatarUrl] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const agencyId = isDistributor ? distributorClientSelectedAgencyId : user.agencyId

  useEffect(() => {
    console.log('selectedClientTypes:', selectedClientTypes)
    async function setDefaultData() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        const agency = await getAgencyById(agencyId, showPageLoadError, setPageLoading)
        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )

        const agents = await getAgentsByAgency(agencyId, showPageLoadError, setPageLoading)

        if (agents) {
          setAgents(agents.results)
        }
        const vchecks = await getClientVCheckEventsById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
          currentPage,
          perPageCount,
          'false',
          selectedClientTypes
        )
        setAgencyName(agency.name)
        setClient(currentClient)

        if (vchecks) {
          setClientVChecks(vchecks.results)

          // pagination
          setRowCount(vchecks.count)
        }
      }
    }
    setDefaultData()
  }, [selectedClientTypes])

  useEffect(() => {
    const filteredClientVChecks = clientVChecks.filter(vcheck => {
      if (vcheck.status === 'on-time') {
        return showOnTimeStatus
      }
      if (vcheck.status === 'late') {
        return showLateStatus
      }
      if (vcheck.status === 'missed') {
        return showMissedStatus
      }
      if (vcheck.status === 'grouped') {
        return showGroupedStatus
      }
      return showOnTimeStatus
    })
    setFilteredClientVChecks(filteredClientVChecks)
    if (!showOnTimeStatus || !showLateStatus || !showMissedStatus || !showGroupedStatus) {
      setRowCount(filteredClientVChecks.length)
    } else {
      if (totalRowCount && totalRowCount > 0) {
        setRowCount(totalRowCount)
      }
    }
  }, [clientVChecks, showOnTimeStatus, showLateStatus, showMissedStatus, showGroupedStatus])

  // Download functions
  const printClientVChecksRef = useRef()
  function dowloadPDF() {
    handlePrintComplianceReport()
  }
  const handlePrintComplianceReport = useReactToPrint({
    content: () => printClientVChecksRef.current,
    documentTitle: `VCheck24 Compliance Report - ${client.last_name}, ${client.first_name}`,
  })

  // CSV download functions
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  function getReview(acceptedBool) {
    if (acceptedBool === null) {
      return 'Incomplete'
    }
    return acceptedBool === true ? 'Accepted' : 'Rejected'
  }

  const convertToClientTimezone = time => time.tz(client.timezone).format('MM/DD/YY hh:mm A z')
  const convertToUserTimezone = time => time.tz(moment.tz.guess()).format('MM/DD/YY hh:mm A z')

  const getReviewerName = vcheck => {
    if (vcheck.reviewed_by.includes('Facial Rec')) {
      return vcheck.reviewed_by
    }
    if (vcheck.role === roleTypeOption.distributorUser) {
      if (user && user.distributorId) {
        return user.firstName.concat(' ', user.lastName)
      }
      return 'Distributor'
    }

    if (vcheck.role === roleTypeOption.agencyUser) {
      return agencyName
    }
    try {
      if (agents && agents.length > 0) {
        const approvedAgent = agents.find(item => item.user.id === vcheck.reviewed_by)
        return approvedAgent.user.first_name.concat(' ', approvedAgent.user.last_name)
      }
    } catch (err) {
      console.log(' Console agents error ', err)
    }
    return null
  }
  // Confirmation Modal functions
  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }
  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }
  const deactivateConfirm = async () => {
    closeConfirmModal()
    await ClientWizard.postClientOverrideFunction(agencyId, client.id, showError, setLoading)
  }

  function downloadCSV() {
    setDownloadState(DOWNLOAD_STATES.downloading)
    const dataToExport = _map(filteredClientVChecks, vcheck => ({
      Scheduled: convertToClientTimezone(moment(vcheck.scheduled)),
      Completed: vcheck.completed ? convertToClientTimezone(moment(vcheck.completed)) : null,
      Type: vcheck.event_type,
      Status: vcheck.status,
      Reviewed_On: vcheck.reviewed_on ? convertToUserTimezone(moment(vcheck.reviewed_on)) : null,
      Reviewed_By: vcheck.reviewed_by ? getReviewerName(vcheck) : null,
      Review: getReview(vcheck.accepted),
    }))
    const JSONToSheet = XLSX.utils.json_to_sheet(dataToExport)
    const worksheet = XLSX.utils.sheet_to_csv(JSONToSheet)
    const filename = `VCheck24 Locations - ${client.last_name}, ${client.first_name}.csv`

    FileSaver.saveAs(new Blob([s2ab(worksheet)], { type: 'application/octet-stream' }), filename)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  // pagination
  const [totalRowCount, setTotalRowCount] = useState()
  const [rowCount, setRowCount] = useState()

  const handlePageOrCountChange = _throttle(async () => {
    const response = await getClientVCheckEventsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      currentPage,
      perPageCount,
      'false',
      selectedClientTypes
    )
    if (response) {
      setClientVChecks(response.results)
      setTotalRowCount(response.count)
      setRowCount(response.count)
      return response.results
    }
  }, 500)

  const handleRowsPerPageChange = async perPage => {
    perPageCount = perPage

    handlePageOrCountChange()
  }

  const handlePageChange = async page => {
    currentPage = page

    handlePageOrCountChange()
  }

  const renderClientAvatar = async clientId => {
    await getClientAvatarlUrl(
      agencyId,
      clientId,
      'gcp_profile_bucket',
      showPageLoadError,
      setImageLoading,
    )
      .then(clavt => {
        setClientAvatarUrl(clavt)
      })
      .catch(() => {
        showPageLoadError(false)
        setClientAvatarUrl(null)
      })
  }

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()
  const [sidebarState, dispatch] = useReducer(sidebarReducer, sidebarInitialState)
  const [reviewRadio, setReviewRadio] = useState(null)

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return sidebarInitialState
  }

  const getVideoData = async vcheck => {
    const response = await ClientWizard.getClientVCheckVideo(
      agencyId,
      client.id,
      vcheck.file,
      showError,
      setVideoLoading,
    )
    dispatch({ fieldName: 'video', data: response.video_url || null })
  }

  // Function to pass into VCheckTable that
  // will open and populate sidebar
  const editInSidebar = vcheck => {
    getVideoData(vcheck)
    renderClientAvatar(vcheck.client_id)
    let completedTime
    if (vcheck.completed) {
      completedTime = moment(vcheck.completed)
        .tz(client.timezone)
        .format('MM/DD/YY hh:mm A z')
    }

    dispatch({
      type: 'update',
      data: {
        id: vcheck.document_id,
        completed: completedTime,
        type: vcheck.type === 'bac_checks' ? 'BAC Check' : 'VCheck',
        client_type: vcheck.type,
        video: vcheck.video,
        file: vcheck.file,
        randomPhrase: vcheck.utterances || [],
        accepted: vcheck.accepted,
        reviewed_by: vcheck.reviewed_by ? getReviewerName(vcheck) : null,
        reviewed_on: vcheck.reviewed_on
          ? convertToClientTimezone(moment(vcheck.reviewed_on))
          : null,
        notes: vcheck.notes,
        bac_result: (vcheck.type === 'bac_checks' && parseFloat(vcheck.bac_result).toFixed(3)) || false,
      },
    })

    openModal()
  }

  // Sidebar functions
  const onClose = () => {
    // clear siderbar form state
    dispatch({ type: 'reset' })
    setReviewRadio(null)
    setShowSidebar(false)
    showError(false)
  }
  const openModal = () => {
    setShowSidebar(true)
  }

  const isFormValid = reviewRadio != null

  // Sidebar submit function
  const onSubmit = async () => {
    console.log('onSubmit', sidebarState)
    let maxRetryCountForStausChange = 10
    let currentVcheck = null
    const vcheckDocumentId = sidebarState.id
    const vcheckReviewToPost = {
      accepted: reviewRadio,
      notes: sidebarState.notes,
      DocumentId: sidebarState.id,
      client_type: sidebarState.client_type
    }
    const updatedVCheckList = await updateClientVCheckEventById(
      agencyId,
      client.id,
      sidebarState.id,
      vcheckReviewToPost,
      showError,
      setLoading,
    )
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    if (updatedVCheckList) {
      while (
        (currentVcheck === null ||
          currentVcheck.reviewed_on === null ||
          currentVcheck.reviewed_on === '') &&
        maxRetryCountForStausChange >= 0
      ) {
        maxRetryCountForStausChange = maxRetryCountForStausChange - 1
        const vChecks = await handlePageOrCountChange()
        currentVcheck = vChecks.filter(x => x.document_id == vcheckDocumentId)[0]
        await sleep(1000)
        console.log('updated current vcheck:' + JSON.stringify(currentVcheck))
      }
      onClose()
    }
  }

  // Build Sidebar header & content
  const sideBarHeader = client.first_name && (
    <Text capitalize>{`${sidebarState.type} - ${client.last_name.concat(
      ', ',
      client.first_name,
    )}`}</Text>
  )

  const sidebarContent = (
    <>
      <Grid
        responsive
        rows={['medium', 'auto', 'auto', 'auto', 'auto']}
        columns={['auto']}
        gap="small"
        areas={[['video'], ['phrase'], ['review'], ['notes'], ['errorMessage']]}
      >
        <Box gridArea="video">
          <Header mini level="3" margin={{ top: 'none' }}>
            {`Completed: ${sidebarState.completed}`}
          </Header>

          {sidebarState.video && sidebarState.file && !videoLoading ? (
            <Box fill>
              <video controls height="100%">
                <source src={sidebarState.video} />
              </video>
            </Box>
          ) : (
            <Box fill justify="center" align="center">
              <Text size="small">Video processing. Please check back later.</Text>
            </Box>
          )}
        </Box>

        {sidebarState.video && (
          <>
            <Box gridArea="phrase">
              <Header mini level="3" margin={{ top: 'none' }}>
                {sidebarState.bac_result ? (
                  <FormattedMessage {...messages.bacHeader} />
                ) : (
                  <FormattedMessage {...messages.phraseHeader} />
                )}
              </Header>

              <Stack anchor="right">

                <Box direction="row">
                  {sidebarState.bac_result ? (
                    <>

                      <Text size="small" color="black" style={{ height: '42px' }}>
                        {sidebarState.bac_result}
                      </Text>
                    </>) : (
                    <>
                      <StyledBox
                        pad="small"
                        justify="center"
                        align="center"
                        elevation="small"
                        background={colors.teal80}
                      >
                        <Text size="small" color="white">
                          {sidebarState.randomPhrase[0]}
                        </Text>
                      </StyledBox>

                      <StyledBox
                        pad="small"
                        justify="center"
                        align="center"
                        elevation="small"
                        background={colors.teal80}
                      >
                        <Text size="small" color="white">
                          {sidebarState.randomPhrase[1]}
                        </Text>
                      </StyledBox>
                    </>
                  )}

                </Box>
                <Box
                  direction="row-responsive"
                  justify="end"
                  align="center"
                  gap="small"
                  style={{ marginTop: '25px' }}
                >
                  <Box
                    background="light-2"
                    justify="center"
                    align="center"
                    pad="none"
                    round="full"
                    height="150px"
                    width="150px"
                  >
                    <ClientAvatar
                      file={clientAvatarUrl ? clientAvatarUrl.avatar_url : null}
                      type={clientAvatarUrl ? clientAvatarUrl.type : null}
                      loading={imageLoading}
                      size="large"
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>

            <Box gridArea="review" direction="column">
              <Header mini level="3" margin={{ top: '3' }}>
                <FormattedMessage {...messages.reviewHeader} />
              </Header>

              {sidebarState.accepted != null ? (
                <Text size="small">{`${sidebarState.accepted ? 'Approved' : 'Rejected'} by ${sidebarState.reviewed_by
                  } on ${sidebarState.reviewed_on}`}</Text>
              ) : (
                <Box direction="row" gap="medium">
                  <StyledBox
                    pad="small"
                    justify="center"
                    align="center"
                    elevation={reviewRadio === true ? 'none' : 'small'}
                    background={reviewRadio === true ? colors.primary : 'transparent'}
                    onClick={() => {
                      setReviewRadio(reviewRadio === true ? null : true)
                    }}
                  >
                    <Text size="small" color={reviewRadio === true ? 'white' : colors.primaryText}>
                      Approve
                    </Text>
                  </StyledBox>

                  <StyledBox
                    pad="small"
                    justify="center"
                    align="center"
                    elevation={reviewRadio === false ? 'none' : 'small'}
                    background={reviewRadio === false ? colors.primary : 'transparent'}
                    onClick={() => {
                      setReviewRadio(reviewRadio === false ? null : false)
                    }}
                  >
                    <Text
                      size="small"
                      color={reviewRadio === false ? 'white' : colors.primaryText}
                    >
                      Reject
                    </Text>
                  </StyledBox>
                </Box>
              )}
            </Box>

            <Box gridArea="notes" direction="column">
              <Header mini level="3" margin={{ top: 'none' }}>
                <FormattedMessage {...messages.notesHeader} />
              </Header>

              {sidebarState.accepted != null ? (
                <TextBlock multiLineString={sidebarState.notes} />
              ) : (
                <TextArea
                  size="small"
                  id="notes"
                  value={sidebarState.notes}
                  onChange={e => dispatch({ fieldName: 'notes', data: e.target.value })}
                ></TextArea>
              )}
            </Box>
          </>
        )}
        <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>
      </Grid>
    </>
  )

  // If we don't have a client id in our data object, display a loading message
  if (!client.id || pageLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }
  return (
    <>
      <Grid
        responsive
        rows={['auto', 'auto']}
        columns={['auto']}
        gap="large"
        pad="medium"
        areas={[['header'], ['table']]}
      >
        <Box gridArea="header" direction="column" justify="between" gap="medium">
          <Box background="transparent" direction="row-responsive">
            <Box
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              direction="row"
              align="center"
              justify="center"
              round
              border={{ color: 'light-3', size: 'xsmall' }}
            >
              <Text size="small">
                <Link to="/clients">Client List</Link>
                {' / '}
                <Link to={`/clients/summary?id=${client.id}`}>{`${client.first_name.concat(
                  ' ',
                  client.last_name,
                )}`}</Link>
                {' / Check-Ins'}
              </Text>
            </Box>
          </Box>

          <Box
            background="transparent"
            direction="row-responsive"
            justify="start"
            align="center"
            gap="small"
          >
            <Box
              background="light-2"
              justify="center"
              align="center"
              pad="none"
              round="full"
              height="150px"
              width="150px"
            >
              <ClientAvatar
                file={ClientWizard.clientAvatarURLValue.avatar_url}
                size="large"
                type={ClientWizard.clientAvatarURLValue.type}
              />
            </Box>

            <Box direction="row-responsive" align="end" gap="medium">
              <Text size="medium">{`${agencyName} / ${client.first_name} ${client.last_name}`}</Text>
              <Box gap="small">
                <Text size="medium">Check-In Type:</Text>
                <Select
                  style={{ padding: '5px', fontSize: 14 }}
                  size="small"
                  name="filter_by_client_types"
                  id="filter_by_client_types"
                  options={options}
                  labelKey="label"
                  valueKey="value"
                  value={options.find(option => option.value === selectedClientTypes)}
                  onChange={({ option }) => {
                    setselectedClientTypes(option.value);
                  }}
                  searchPlaceholder="Client Types"
                  placeholder="No matches found"
                />
              </Box>
            </Box>
          </Box>

          <Box direction="row" justify="between" align="center" gap="small">
            <Box direction="column" gap="small">
              <Text size="large">Status Filter: </Text>
              <Box direction="row" gap="medium">
                <Checkbox
                  label="On-Time"
                  name="on-time"
                  checked={showOnTimeStatus}
                  onChange={event => setShowOnTimeStatus(event.target.checked)}
                ></Checkbox>
                <Checkbox
                  label="Late"
                  name="late"
                  checked={showLateStatus}
                  onChange={event => setShowLateStatus(event.target.checked)}
                ></Checkbox>
                <Checkbox
                  label="Missed"
                  name="missed"
                  checked={showMissedStatus}
                  onChange={event => setShowMissedStatus(event.target.checked)}
                ></Checkbox>
                <Checkbox
                  label="Grouped"
                  name="grouped"
                  checked={showGroupedStatus}
                  onChange={event => setShowGroupedStatus(event.target.checked)}
                ></Checkbox>
              </Box>
            </Box>
            {filteredClientVChecks && filteredClientVChecks.length > 0 && (
              <Box gap="small">
                {downloadState === DOWNLOAD_STATES.downloaded && (
                  <Text color="focus" weight="bold" size="small" margin={{ horizontal: 'small' }}>
                    File has been downloaded.
                  </Text>
                )}

                <StyledButton
                  color={colors.primary}
                  disabled={downloadState === DOWNLOAD_STATES.downloading}
                  label="Download PDF"
                  onClick={dowloadPDF}
                  reverse
                ></StyledButton>

                <StyledButton
                  color={colors.primary}
                  disabled={downloadState === DOWNLOAD_STATES.downloading}
                  label="Download CSV"
                  onClick={downloadCSV}
                  reverse
                ></StyledButton>
                <StyledButton color="status-warning" onClick={openConfirmModal} reverse>
                  <Box margin="small" justify="center" alignContent="center">
                    <Text size="small" color="white">
                      Reset Facial Recognition
                    </Text>
                    <Text size="small" color="white" alignSelf="center">
                      Reference Image
                    </Text>
                  </Box>
                </StyledButton>
              </Box>
            )}
          </Box>
        </Box>

        {!!showConfirmModal && (
          <Layer position="center" onClickOutside={closeConfirmModal} onEsc={closeConfirmModal}>
            <Box pad="large" gap="small" width="medium" justify="center" align="center">
              <Text size="medium" weight="bold">
                <FormattedMessage {...messages.confirmModalHeader} />
              </Text>

              <Box gap="small">
                <>
                  <Text size="small" textAlign="center">
                    <FormattedMessage {...messages.resetMessage} />
                  </Text>
                </>
              </Box>

              <Box direction="row" gap="small">
                <Button
                  color="status-unknown"
                  label={intl.formatMessage(messages.cancelButton)}
                  primary={false}
                  margin={{ bottom: 'none' }}
                  onClick={closeConfirmModal}
                />

                <Button
                  color="status-error"
                  label={intl.formatMessage(messages.resetButton)}
                  margin={{ bottom: 'none' }}
                  onClick={deactivateConfirm}
                  disabled={loading}
                />
              </Box>

              <Box>{deactivateError && <Message message={deactivateError} isError />}</Box>
            </Box>
          </Layer>
        )}

        <Box gridArea="table" fill>
          <ClientVCheckTable
            loading={loading}
            disabled={loading}
            clientVChecks={filteredClientVChecks}
            clientTimezone={client.timezone}
            editInSidebar={editInSidebar}
            rowCount={rowCount}
            agents={agents}
            user={user}
            agencyName={agencyName}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          ></ClientVCheckTable>
        </Box>

        <Box style={{ overflow: 'hidden', height: 0 }}>
          <PrintableClientVCheckPage
            client={client}
            clientVChecks={filteredClientVChecks}
            clientAvatarURLValue={ClientWizard.clientAvatarURLValue.avatar_url}
            type={ClientWizard.clientAvatarURLValue.type}
            agents={agents}
            user={user}
            agencyName={agencyName}
            rowCount={rowCount}
            ref={printClientVChecksRef}
          />
        </Box>
      </Grid>

      {!!showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          isFormValid={isFormValid}
          onSubmit={onSubmit}
          header={sideBarHeader}
          content={sidebarContent}
        ></WizardSidebar>
      )}
    </>
  )
})

const StyledBox = styled(Box)`
  border: 1px solid #f2f2f2;
  min-width: 100px;
`
const StyledButton = styled(Button)`
  margin: 0 !important;
`

ClientVCheckPage.propTypes = {
  intl: intlShape.isRequired,
}
export default injectIntl(ClientVCheckPage)
