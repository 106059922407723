/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* global google */
import React, { useContext, useEffect, useReducer, useState, useRef, useMemo } from 'react'
import { Header as GrommetHeader, ResponsiveContext } from 'grommet'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { orderBy, throttle as _throttle } from 'lodash'
import { Add } from 'grommet-icons'

// Components
import { AgencyZonesTable } from 'components/AgencyZonesTable'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { FormField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Message } from 'components/Message'
import { RangeInput } from 'components/RangeInput'
import { Text } from 'components/Text'
import { TextInput } from 'components/TextInput'
import { WizardSidebar } from 'components/WizardSidebar'
import { GoogleMaps } from 'components/Maps'

import { Select } from '../../components/Select'

// Stores
import { UserStoreContext } from '../../stores/UserStore'
import { ZoneStoreContext } from '../../stores/ZoneStore'
import { DistributorStoreContext } from '../../stores/DistributorStore'
import { AgencyStoreContext } from '../../stores/AgencyStore'

// Utils, Services & Messages
import messages from './ZonesPage.messages'
import {
  getCoordinateObject,
  initialPaginationConstants as initialPagination,
} from '../../utils/helpers'
import placesAddressParser from '../../utils/placesAddressParser'
import useFlashMessage from '../../hooks/FlashMessage'

// Styles
import colors from '../../utils/colors'

const GEOFENCE_TYPE = {
  circle: 'circle',
  polygon: 'polygon',
  none: 'none',
}
const minimumGeofenceDiameter = 300
const minimumGeofenceArea = 10765

const sidebarInitialState = {
  name: '',
  address: '',
  agencyName: '',
  agency: '',
  geofence_type: GEOFENCE_TYPE.none,
  point: {
    type: 'Point',
    coordinates: [-73.93, 40.73], // lng, lat
  },
}

let rowsPerPage = initialPagination.rows
let currentPage = initialPagination.pageNumber
/**
 *
 * ZonesPage
 *
 * This page will act as a landing page for all Agency Zone related tasks
 * - display all existing global zones
 * - add/edit/delete global zones
 *
 */
const ZonesPage = observer(({ intl }) => {
  const zoneStore = useContext(ZoneStoreContext)
  const agencyStore = useContext(AgencyStoreContext)
  const distributorStore = useContext(DistributorStoreContext)
  const { user, isDistributor, distributorId } = useContext(UserStoreContext)

  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [pageLoadError, showPageLoadError] = useState(null)
  const [loading, setLoading] = useState(null)
  const [pageLoading, setPageLoading] = useState(true)
  const [agencyNames, setAgencyNames] = useReducer((state, agency) => [...state, agency], ['All'])
  const [agencyName, setAgencyName] = useState('')
  const [agencyId, setAgencyId] = useState(!isDistributor ? user.agencyId : '')
  const [selectedAgencyId, setSelectedAgencyId] = React.useState()
  const [agentsLoading, setAgentsLoading] = useState(true)
  const [rowCount, setRowCount] = useState()
  const [isNewZone, setIsNewZone] = useState(false)
  const [allAgenciesInDistributorWithIds, setAllAgenciesInDistributorWithIds] = useReducer(
    (state, agency) => [...state, agency],
    [],
  )
  const [searchText, setSearchText] = React.useState('')
  const [selectedAgency, setSelectedAgency] = React.useState('All')
  const [filteredDistributorZones, setFilteredDistributorZones] = useState()
  const [filteredZones, setFilteredZones] = useState()
  const [checkValidation, setCheckValidation] = useState(false)

  const getDistributorLevelGlobalZones = async () => {
    const response = await zoneStore.getZonesByDistributorId(
      distributorId,
      setAgentsLoading,
      showPageLoadError,
      currentPage,
      rowsPerPage,
      selectedAgencyId,
      searchText,
    )
    if (response) {
      setRowCount(response.count)
    }
  }

  const getAgencyLevelGlobalZones = async () => {
    const response = await zoneStore.getAgencyZones(
      user.agencyId,
      showPageLoadError,
      setAgentsLoading,
      currentPage,
      rowsPerPage,
      searchText,
    )
    if (response) {
      setRowCount(response.count)
    }
    if (pageLoading) {
      setPageLoading(false)
    }
    const agency = await agencyStore.getAgencyById(agencyId, showPageLoadError, setAgentsLoading)
    setAgencyName(agency.name)
  }

  useEffect(() => {
    if (isDistributor) {
      getDistributorLevelGlobalZones()
    } else {
      getAgencyLevelGlobalZones()
    }
  }, [searchText, selectedAgencyId])

  const resetPage = () => {
    if (currentPage > 1) {
      rowsPerPage = initialPagination.rows
      currentPage = initialPagination.pageNumber
    }
  }

  useEffect(() => {
    const zones = isDistributor ? zoneStore.distributorZones : zoneStore.agencyZones

    if (isDistributor) {
      setFilteredDistributorZones(zones)
    } else {
      setFilteredZones(zones)
    }
  }, [zoneStore.agencyZones, zoneStore.distributorZones])

  // Filling dropdown for Agencies
  useEffect(() => {
    async function getAgencies() {
      const response = await distributorStore.getAgenciesByDistributorId(
        distributorId,
        setPageLoading,
        showPageLoadError,
      )
      const agencies = response.results
      const orderedAgencies = orderBy(agencies, ['name'], ['asc'])

      orderedAgencies.forEach(agency => {
        const displayName = agency.name
        setAgencyNames(displayName)

        setAllAgenciesInDistributorWithIds({
          agency_name: displayName,
          agency_id: agency.id,
        })
      })

      setAllAgenciesInDistributorWithIds({
        agemcy_name: 'All',
        agency_id: 0,
      })
    }
    if (isDistributor) {
      getAgencies()
    }
  }, [])

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()
  const [sidebarState, dispatch] = useReducer(sidebarReducer, sidebarInitialState)
  const [isEditing, setIsEditing] = useState(false)

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return sidebarInitialState
  }

  // map data
  const mapRef = useRef(null)
  const geofenceType = useRef(GEOFENCE_TYPE.none) // this will be set from API
  const geofenceCircle = useRef(null)
  const geofencePolygon = useRef(null)
  const geofencePolygonCoordiantes = useRef([])
  const currentMarker = useRef(null)
  const diameterSlideControl = useRef()
  const polygonAreaLabel = useRef()

  const [diameterSlide, setDiameterSlide] = useState(minimumGeofenceDiameter)
  const [geofenceArea, setgeofenceArea] = useState(0)
  const size = useContext(ResponsiveContext)
  // map functions
  const mapObj = useMemo(
    () => (
      <GoogleMaps
        center={sidebarState.point ? getCoordinateObject(sidebarState.point.coordinates) : null}
        enableDrawingmode
        onPolygonCompleted={polygon => {
          polygon.setMap(null)
          renderGeofence('polygon', polygon)
        }}
        onCircleCompleted={circle => {
          circle.setMap(null)
          renderGeofence('circle', circle)
        }}
        onMapLoaded={map => {
          if (map) {
            mapRef.current = map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED // # Need to review the reference
            createGeofenceFromLocalStore()
          }
        }}
      />
    ),
    [sidebarState.point],
  )

  function clearMap() {
    if (geofenceCircle.current) {
      geofenceCircle.current.setMap(null)
      geofenceCircle.current = null
    }
    if (geofencePolygon.current) {
      geofencePolygon.current.setMap(null)
      geofencePolygon.current = null
    }
    if (currentMarker.current) {
      currentMarker.current.setMap(null)
      currentMarker.current = null
    }
  }
  function renderMarker(markerLat, markerLng) {
    currentMarker.current = new google.maps.Marker({
      position: { lat: markerLat, lng: markerLng },
      map: mapRef.current,
    })
  }
  function renderGeofence(type, geofence) {
    clearMap()
    diameterSlideControl.current.hidden = !(
      diameterSlideControl.current && type === GEOFENCE_TYPE.circle
    )
    polygonAreaLabel.current.hidden = !(polygonAreaLabel.current && type === GEOFENCE_TYPE.polygon)
    if (type === GEOFENCE_TYPE.circle) {
      geofenceCircle.current = new google.maps.Circle({
        strokeWeight: 2,
        fillColor: colors.purple,
        fillOpacity: 0.3,
        map: mapRef.current,
        center: { lat: geofence.center.lat(), lng: geofence.center.lng() },
        radius: geofence ? geofence.radius : diameterSlide / 6.6,
      })
      geofenceType.current = GEOFENCE_TYPE.circle
      setDiameterSlide((geofence.radius * 6.6).toFixed(0))

      // Drawing the Marker
      renderMarker(geofence.center.lat(), geofence.center.lng())
      // reset the zoom level
      if (mapRef && geofenceCircle.current)
        mapRef.current.fitBounds(geofenceCircle.current.getBounds())
    } else if (type === GEOFENCE_TYPE.polygon) {
      geofencePolygon.current = new google.maps.Polygon({
        paths: geofence.getPath().getArray(),
        strokeWeight: 2,
        fillColor: colors.purple,
        fillOpacity: 0.3,
        map: mapRef.current,
      })
      geofencePolygonCoordiantes.current = []
      geofence
        .getPath()
        .getArray()
        .forEach(element => {
          geofencePolygonCoordiantes.current.push([element.lng(), element.lat()])
        })
      geofencePolygonCoordiantes.current.push(geofencePolygonCoordiantes.current[0])
      geofenceType.current = GEOFENCE_TYPE.polygon
      const geofenceArea = google.maps.geometry.spherical.computeArea(geofence.getPath())
      setgeofenceArea((geofenceArea * 10.76).toFixed(2))

      // Drawing the Marker
      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < geofence.getPath().length; i += 1) {
        bounds.extend(geofence.getPath().getAt(i))
      }
      renderMarker(bounds.getCenter().lat(), bounds.getCenter().lng())
      // reset the zoom level
      if (mapRef) mapRef.current.fitBounds(bounds)
    }
  }

  function handleSelection(selection) {
    // geocode to get coordinates
    dispatch({ fieldName: 'display_address', data: selection.description })
    geocodeByAddress(selection.description).then(results => {
      const addressFields = placesAddressParser.buildAddressField(results[0])
      getLatLng(results[0]).then(({ lat, lng }) => {
        dispatch({
          fieldName: 'point',
          data: {
            type: 'Point',
            coordinates: [lng, lat],
          },
        })
        // set address and places fields in zoneFormState
        dispatch({ fieldName: 'address', data: addressFields })
        dispatch({ fieldName: 'formatted_address', data: results[0].formatted_address })
        dispatch({ fieldName: 'google_places_data', data: results[0] })
      })
    })

    // set places id in zoneFormState
    dispatch({ fieldName: 'google_places_id', data: selection.google_places_id })
    setCheckValidation(true)
  }
  const createGeofenceFromLocalStore = () => {
    if (sidebarState) {
      const lat = parseFloat(sidebarState.point.coordinates[1])
      const lng = parseFloat(sidebarState.point.coordinates[0])
      let geofence = null

      if (sidebarState.geofence_type === GEOFENCE_TYPE.circle) {
        geofence = new google.maps.Circle({
          center: { lat, lng },
          radius: sidebarState.radius,
        })
      } else if (sidebarState.geofence_type === GEOFENCE_TYPE.polygon) {
        geofencePolygonCoordiantes.current = []
        if (sidebarState.polygon.coordinates.length > 0) {
          const paths = sidebarState.polygon.coordinates[0]
          paths.forEach(element => {
            geofencePolygonCoordiantes.current.push({
              lat: element[1],
              lng: element[0],
            })
          })
          geofence = new google.maps.Polygon({
            paths: geofencePolygonCoordiantes.current,
          })
        }
      }
      if (geofence) renderGeofence(sidebarState.geofence_type, geofence)
      else if (sidebarState.point)
        renderMarker(sidebarState.point.coordinates[1], sidebarState.point.coordinates[0])
    }
  }
  // Function to pass into ZoneTable that
  // will open and populate sidebar for editing
  const editInSidebar = zone => {
    setIsEditing(true)

    const agencyInDistributor = allAgenciesInDistributorWithIds.find(
      agency => agency.agency_name === zone.agency_name,
    )
    isDistributor ? setAgencyId(agencyInDistributor.agency_id) : ''
    setIsNewZone(false)

    dispatch({
      type: 'update',
      data: {
        id: zone.id,
        name: zone.name,
        agency: isDistributor ? agencyInDistributor.agency_id : agencyId,
        agencyName: zone.agency_name,
        address: zone.address,
        point: null,
        geofence_type: zone.geofence_type,
        radius: zone.radius,
        polygon: zone.polygon,
      },
    })
    // call handleSelection with address as description to populate map data
    handleSelection({
      description: zone.formatted_address,
      google_places_id: zone.google_places_id,
    })
    setDiameterSlide((zone.radius * 6.6).toFixed(0)) // radius m to diameter ft

    // open modal
    setIsNewZone(false)
    openModal()
    if (checkValidation) {
      setCheckValidation(false)
    }
  }

  // Sidebar functions
  const onClose = () => {
    // clear siderbar form state
    dispatch({ type: 'reset' })
    setIsEditing(false)
    setShowSidebar(false)
    setDiameterSlide(3300)
  }
  const openModal = () => {
    setShowSidebar(true)
  }

  const isFormValid =
    !!sidebarState.address &&
    !!sidebarState.name &&
    (geofenceType.current === GEOFENCE_TYPE.circle
      ? diameterSlide >= minimumGeofenceDiameter
      : geofenceArea >= minimumGeofenceArea)

  const handlePageOrCountChange = _throttle(async () => {
    if (isDistributor) {
      getDistributorLevelGlobalZones()
    } else {
      getAgencyLevelGlobalZones()
    }
  }, 500)
  const handleRowsPerPageChange = async perPage => {
    rowsPerPage = perPage
    handlePageOrCountChange()
  }

  const handlePageChange = async page => {
    currentPage = page
    handlePageOrCountChange()
  }

  // Sidebar submit function
  const onSubmit = async () => {
    let pointLat
    let pointLng
    if (geofenceType.current === GEOFENCE_TYPE.circle) {
      pointLat = geofenceCircle.current.center.lat()
      pointLng = geofenceCircle.current.center.lng()
    } else {
      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < geofencePolygon.current.getPath().length; i += 1) {
        bounds.extend(geofencePolygon.current.getPath().getAt(i))
      }
      pointLat = bounds.getCenter().lat()
      pointLng = bounds.getCenter().lng()
    }

    const zoneToPost = isDistributor
      ? {
          name: sidebarState.name,
          agency: sidebarState.agency,
          geofence_type: geofenceType.current,
          point: {
            type: 'Point',
            coordinates: [pointLng, pointLat],
          },
          polygon: {
            type: 'Polygon',
            coordinates: [geofencePolygonCoordiantes.current],
          },
          radius:
            geofenceType.current === GEOFENCE_TYPE.circle
              ? (diameterSlide / 6.6).toFixed(2)
              : minimumGeofenceDiameter, // convert diameter (ft) to radius (m)
          address: sidebarState.address,
          google_places_id: sidebarState.google_places_id ? sidebarState.google_places_id : '', // check if place id is a valid element
          google_places_data: sidebarState.google_places_data,
        }
      : {
          name: sidebarState.name,
          agency: agencyId,
          geofence_type: geofenceType.current,
          point: {
            type: 'Point',
            coordinates: [pointLng, pointLat],
          },
          polygon: {
            type: 'Polygon',
            coordinates: [geofencePolygonCoordiantes.current],
          },
          radius:
            geofenceType.current === GEOFENCE_TYPE.circle
              ? (diameterSlide / 6.6).toFixed(2)
              : minimumGeofenceDiameter, // convert diameter (ft) to radius (m)
          address: sidebarState.address,
          google_places_id: sidebarState.google_places_id ? sidebarState.google_places_id : '', // check if place id is a valid element
          google_places_data: sidebarState.google_places_data,
        }

    if (sidebarState.id) {
      // update
      const updatedZoneToPost = {
        ...zoneToPost,
        id: sidebarState.id,
      }

      const requestSuccess = await zoneStore.updateAgencyZoneById(
        agencyId,
        sidebarState.id,
        updatedZoneToPost,
        setLoading,
        showError,
      )
      if (requestSuccess) {
        onClose()
        handlePageChange()
      }
    } else {
      // post

      const requestSuccess = await zoneStore.postZoneToAgency(
        agencyId,
        zoneToPost,
        setLoading,
        showError,
      )
      // will be false if no caught errors above
      if (requestSuccess) {
        onClose()
        handlePageChange()
      }
    }
  }

  const onDelete = async () => {
    if (sidebarState.id) {
      const requestSuccess = await zoneStore.deleteAgencyZoneById(
        agencyId,
        sidebarState.id,
        setLoading,
        showError,
      )

      if (requestSuccess) {
        onClose()
      }
    }
  }

  // Build Sidebar header & content
  const sideBarHeader = isEditing ? (
    <FormattedMessage {...messages.sidebarHeader} />
  ) : (
    <FormattedMessage {...messages.addZoneButton} />
  )

  const sidebarContent = (
    <Grid
      columns={['1']}
      rows={['1/6', '1/6', '1/6', 'auto']}
      areas={[['name'], ['address'], ['map'], ['errorMessage']]}
      gap="small"
    >
      <Box gridArea="name">
        <Box direction="column" flex="grow">
          {isDistributor ? (
            <FormField
              component={TextInput}
              id="agency_name"
              name="agency_name"
              label={intl.formatMessage(messages.agencyNameLabel)}
              required
              value={{ value: '' }}
              margin={{ top: 'small', bottom: 'small' }}
            >
              <Select
                plain
                size="small"
                name="agency_name"
                id="agency_name"
                alignSelf="stretch"
                placeholder="Select Agency"
                options={agencyNames.filter(item => item !== 'All')}
                value={sidebarState.agencyName}
                onChange={e => {
                  dispatch({ fieldName: 'agencyName', data: e.target.value })
                  const agencyInDistributor = allAgenciesInDistributorWithIds.find(
                    agency => agency.agency_name === e.target.value,
                  )
                  dispatch({ fieldName: 'agency', data: agencyInDistributor.agency_id })
                  if (isNewZone) {
                    setAgencyId(agencyInDistributor.agency_id)
                  }
                }}
              ></Select>
            </FormField>
          ) : (
            <Box direction="row" align="start">
              <Box style={{ fontSize: 14 }}>Agency Name -</Box>
              <Box style={{ fontSize: 14, color: '#666666' }} pad={{ horizontal: 'small' }}>
                {agencyName}
              </Box>
            </Box>
          )}
          <Header mini level="5" margin={{ top: 'small', bottom: 'small' }}>
            <FormattedMessage {...messages.locationDetailsHeader} />
          </Header>
        </Box>

        <FormField
          component={TextInput}
          id="location_name"
          name="location_name"
          label={intl.formatMessage(messages.locationNameLabel)}
          required
          onChange={e => dispatch({ fieldName: 'name', data: e.target.value })}
          value={{ value: sidebarState.name }}
        ></FormField>
      </Box>

      <Box gridArea="address">
        <FormField
          component={PlacesAutocomplete}
          id="location_address"
          name="location_address"
          label={intl.formatMessage(messages.locationAddressLabel)}
          value={{ value: sidebarState.display_address }}
          inputStyle={{ width: '100%', fontSize: '14px', border: 'none' }}
          onSelect={handleSelection}
          placeholder={sidebarState.display_address}
          onChange={() => {
            if (checkValidation) {
              setCheckValidation(false)
            }
          }}
          suggestionsStyles={{
            container: {
              color: colors.secondary,
              fontWeight: 'bold',
            },
            suggestion: {
              fontSize: '14px',
              padding: '5px 10px',
            },
          }}
        ></FormField>
        {!sidebarState.address && checkValidation && (
          <Box margin={{ horizontal: 'small' }}>
            <Message message={intl.formatMessage(messages.geoAddressValidation)} isError />
          </Box>
        )}
      </Box>

      <Box gridArea="map" height="medium" margin={{ vertical: 'small' }}>
        {sidebarState.point && <>{mapObj}</>}
        <div ref={diameterSlideControl}>
          <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
            <FormattedMessage {...messages.geofenceDiameterLabel} />
            {diameterSlide > 5200 ? 5280 : diameterSlide}
            {' ft'}
          </Header>

          <RangeInput
            min={minimumGeofenceDiameter}
            max={5300}
            step={100}
            value={diameterSlide}
            onChange={e => {
              const circle = geofenceCircle.current
              circle.radius = e.target.value / 6.6
              renderGeofence(GEOFENCE_TYPE.circle, circle)
              setDiameterSlide(e.target.value)
            }}
          />
          {geofenceType.current === GEOFENCE_TYPE.circle &&
            diameterSlide < minimumGeofenceDiameter && (
              <Text size="small" color="status-critical">
                <FormattedMessage {...messages.geofenceMinDiameterInfo} />
              </Text>
            )}
        </div>
        <div ref={polygonAreaLabel}>
          {geofenceType.current === GEOFENCE_TYPE.polygon && (
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.geofenceAreaLabel} />
              {geofenceArea}
              {' (SQUARE FEET) '}
            </Header>
          )}
          {geofenceType.current === GEOFENCE_TYPE.polygon && geofenceArea < minimumGeofenceArea && (
            <Text size="small" color="status-critical">
              <FormattedMessage {...messages.geofenceMinAreaInfo} />
            </Text>
          )}
        </div>
      </Box>

      <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>
    </Grid>
  )

  if (pageLoading) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }

  return (
    <>
      {/** Header Section */}
      <Box
        as="header"
        background="light-1"
        pad={{ horizontal: 'medium', vertical: 'small' }}
        style={{ boxShadow: 'inset 0 -1px 0 0 #d9d9d9' }}
      >
        {/* Search and Filters */}
        <Grid
          rows={['auto']}
          columns={size === 'small' ? ['auto'] : ['1/4', '1/2', '1/4']}
          gap="medium"
          align="center"
        >
          <Box direction="row" align="center" pad="small">
            <TextInput
              style={{
                background: 'white',
                borderRadius: '27px',
                border: 'solid 1px #d9d9d9',
                fontSize: 14,
                maxWidth: '300px',
              }}
              placeholder="Search Zone List"
              value={searchText}
              onChange={event => setSearchText(event.target.value)}
            />
          </Box>

          {isDistributor && (
            <Box direction={size === 'small' ? 'column' : 'row'} align="center" gap="small">
              <Box pad={{ horizontal: 'small', vertical: 'small' }} style={{ fontSize: 14 }}>
                Filter By:
              </Box>
              <Select
                style={{
                  padding: '5px',
                  fontSize: 14,
                  width: size === 'small' ? '100%' : '200px',
                }}
                options={agencyNames}
                value={selectedAgency}
                onChange={option => {
                  resetPage()
                  const agencyInDistributor = allAgenciesInDistributorWithIds.find(
                    agency => agency.agency_name === option.value,
                  )
                  setSelectedAgency(option.value)
                  if (option.value === 'All') {
                    setSelectedAgencyId('')
                    return
                  }
                  if (agencyInDistributor) setSelectedAgencyId(agencyInDistributor.agency_id)
                }}
              />
            </Box>
          )}

          {!isDistributor && (
            <Box direction={size === 'small' ? 'column' : 'row'} align="center">
              <Box style={{ fontSize: 14 }}>Agency Name -</Box>
              <Box style={{ fontSize: 14, color: '#666666' }} pad={{ horizontal: 'small' }}>
                {agencyName}
              </Box>
            </Box>
          )}

          {/* Add Button */}
          <Box pad="small">
            <Button
              primary
              color="accent-1"
              icon={<Add size="small" />}
              label={intl.formatMessage(messages.addZoneButton)}
              onClick={() => {
                openModal()
                setIsNewZone(true)
                setCheckValidation(false)
              }}
              style={{
                borderRadius: 27,
                fontSize: 15,
                fontWeight: 'bold',
                width: size === 'small' ? '100%' : 170,
              }}
            />
          </Box>
        </Grid>
      </Box>

      {/** Table Section */}
      <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
        <Grid rows={['auto']} columns={['auto']} gap="small" areas={[['table']]}>
          <Box pad="medium" gridArea="table">
            <AgencyZonesTable
              loading={agentsLoading}
              agencyZones={isDistributor ? filteredDistributorZones : filteredZones}
              isDistributorUser={isDistributor}
              editInSidebar={editInSidebar}
              rowCount={rowCount}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Grid>
      </Box>

      {/** Sidebar Section */}
      {showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          isFormValid={isFormValid}
          isAbleToDelete={isEditing}
          onSubmit={onSubmit}
          onDelete={onDelete}
          header={sideBarHeader}
          content={sidebarContent}
        />
      )}
    </>
  )
})

ZonesPage.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ZonesPage)
