import { defineMessages } from 'react-intl'

export const scope = 'app.containers.AgentHomePage'

/*
 * AgentHomePage Messages
 *
 * This contains all the text for the AgentHomePage container.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AgentHomePage container!',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Event Data...',
  },
  phraseHeader: {
    id: `${scope}.phraseHeader`,
    defaultMessage: 'Random Phrase'
  },
  bacHeader: {
    id: `${scope}.bacHeader`,
    defaultMessage: 'BAC Result'
  },
  reviewHeader: {
    id: `${scope}.reviewHeader`,
    defaultMessage: 'Review',
  },
  notesHeader: {
    id: `${scope}.notesHeader`,
    defaultMessage: 'Notes',
  },
})
