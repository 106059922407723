import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import 'moment-timezone'
import DataTable from 'react-data-table-component'

// Components
import { TableHeader } from '../Table'
import { Text } from '../Text'

// Utils, Services & Messages
import messages from './ClientVCheckTable.messages'
import { getTablePaginationOptions, roleTypeOption } from '../../utils/helpers'
import { LoadingIndicator } from '../LoadingIndicator'

/**
 * ClientVCheckTable
 *
 * This component takes in a vcheck array and converts it into the
 * required format before passing to react-data-table-component
 *
 * Timezone Notes:
 * There are three date times displayed in this table
 *  - scheduled (displayed in client timezone because it is client-created)
 *  - completed (displayed in client timezone because it is client-created)
 *  - reviewed on (displayed in user timezone because it is user-created)
 *
 */
const ClientVCheckTable = ({
  loading,
  printable,
  clientVChecks,
  clientTimezone,
  agents,
  user,
  agencyName,
  editInSidebar,
  rowCount,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const dataTableHeaders = [
    {
      selector: 'scheduled',
      format: row => convertToClientTimezone(moment(row.scheduled)),
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      style: { 'font-size': 14 },
      grow: 1.5,
      sortable: true, // will only sort what is currently displayed
      sortFunction: (rowA, rowB) => new Date(rowA.scheduled) - new Date(rowB.scheduled),
      wrap: true,
    },
    {
      selector: 'completed',
      format: row => {
        if (row.completed && row.status !== 'missed') {
          return convertToClientTimezone(moment(row.completed))
        }
        return 'N/A'
      },
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      style: { 'font-size': 14 },
      grow: 1.5,
      wrap: true,
    },
    {
      selector: 'checkin',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      style: { 'font-size': 14 },
      grow: 1.5,
      wrap: true,
    },
    {
      selector: 'type',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.8 : 1,
      wrap: true,
    },
    {
      selector: 'bacresult',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.8 : 1,
      wrap: true,
    },
    {
      selector: 'status',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSix} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.3 : 0.5,
      wrap: true,
    },
    {
      selector: 'location',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSeven} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: 1.5
    },
    {
      selector: 'reviewedOn',
      format: row => (row.reviewedOn ? convertToClientTimezone(moment(row.reviewedOn)) : null),
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderEight} />
        </StyledTableHeader>
      ),
      style: { 'font-size': 14 },
      grow: 1.5,
      wrap: true,
    },
    {
      selector: 'reviewedBy',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderNine} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'facialRegStatus',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderEleven} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.7 : 1,
      wrap: true,
    },
    {
      selector: 'review',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTen} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.7 : 1,
      wrap: true,
    },
    {
      selector: 'vcheck',
      omit: true,
    },
  ]
  const bodyData = []

  const convertToClientTimezone = time => time.tz(clientTimezone).format('MM/DD/YY hh:mm A z')
  // const convertToUserTimezone = time => time.tz(moment.tz.guess()).format('MM/DD/YY hh:mm A z')

  const getReviewerName = vcheck => {
    if (vcheck.reviewed_by.includes('Facial Rec')) {
      return vcheck.reviewed_by
    }
    if (vcheck.role === roleTypeOption.distributorUser) {
      if (user && user.distributorId) {
        return user.firstName.concat(' ', user.lastName)
      }
      return 'Distributor'
    }
    if (vcheck.role === roleTypeOption.agencyUser) {
      return agencyName
    }
    try {
      if (agents && agents.length > 0) {
        const approvedAgent = agents.find(item => item.user.id === vcheck.reviewed_by)
        return approvedAgent.user.first_name.concat(' ', approvedAgent.user.last_name)
      }
    } catch (err) {
      console.log(' Console agents error ', err)
    }
    return null
  }

  const getReviewStatus = status => {
    let recognitionStatus = ''

    switch (status) {
      case 'pending':
        recognitionStatus = 'Not Initiated'
        break
      case 'inprogress':
        recognitionStatus = 'In Progress'
        break
      case 'inititated':
        recognitionStatus = 'Not Initiated'
        break
      default:
        recognitionStatus = status
        break
    }

    return (
      <Text
        size="small"
        color={
          status === 'pending' || status === 'inititated' || status === 'inprogress'
            ? 'status-warning'
            : null
        }
      >
        {recognitionStatus}
      </Text>
    )
  }

  const getReview = acceptedState => {
    if (acceptedState.status === 'missed' || acceptedState.status === 'grouped') {
      return null
    }
    if (acceptedState.accepted === null || acceptedState.accepted === undefined) {
      return (
        <Text size="small" color="status-warning">
          Not Reviewed
        </Text>
      )
    }
    return <Text size="small">{acceptedState.accepted === true ? 'Approved' : 'Rejected'}</Text>
  }

  const getVcheckLocation = vcheck => {
    try {
      if (vcheck && vcheck.address && vcheck.address.formattedAddress) {
        return vcheck.address.formattedAddress
      }
      return 'Unknown'
    } catch (err) {
      return 'Unknown'
    }
  }

  clientVChecks.forEach(vcheck => {
    const vcheckRowObject = {
      scheduled: vcheck.scheduled || '',
      completed: vcheck.completed || '',
      type: (vcheck.type==='bac_checks' && vcheck.bac_result) ? `BAC -${vcheck.event_type}` : vcheck.event_type || '',
      status: vcheck.status || 'Not Found',
      reviewedOn: vcheck.reviewed_on || '',
      reviewedBy: vcheck.role === 0 ? '' : getReviewerName(vcheck),
      review: getReview(vcheck),
      facialRegStatus: getReviewStatus(vcheck.facial_recognition_status || ''),
      bacresult: (vcheck.type === 'bac_checks' && parseFloat(vcheck.bac_result).toFixed(3)) || '',
      location: getVcheckLocation(vcheck),
      checkin: (vcheck.type==='bac_checks' && vcheck.bac_result) ? `BAC Check` : 'VCheck',
      vcheck,
    }
    bodyData.push(vcheckRowObject)
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <StyledDataTable
      noHeader
      border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
      columns={dataTableHeaders}
      data={bodyData}
      defaultSortField="scheduled"
      defaultSortAsc={false}
      striped
      highlightOnHover
      pointerOnHover
      onRowClicked={e => {
        if (
          (e.status === 'on-time' || e.status === 'late') &&
          (e.vcheck.facial_recognition_status !== 'inprogress' ||
            !e.vcheck.facial_recognition_status)
        ) {
          editInSidebar(e.vcheck)
        }
      }}
      pagination={!printable}
      paginationServer
      paginationTotalRows={rowCount}
      paginationPerPage={25}
      paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
      onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
      onChangePage={e => handlePageChange(e)}
      persistTableHead
      {...fieldProps}
    />
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

ClientVCheckTable.propTypes = {
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  clientVChecks: PropTypes.array,
  clientTimezone: PropTypes.string,
  agents: PropTypes.array,
  agencyName: PropTypes.array,
  user: PropTypes.any,
  editInSidebar: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default ClientVCheckTable
