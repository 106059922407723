/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState, useReducer } from 'react'
import { Header as GrommetHeader } from 'grommet'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { throttle as _throttle } from 'lodash'
// Components
import { VCheckReviewTable } from 'components/VCheckReviewTable'
import { Box } from 'components/Box'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Message } from 'components/Message'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { TextBlock } from 'components/TextBlock'
import { WizardSidebar } from 'components/WizardSidebar'
import { Select } from '../../components/Select'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { UserStoreContext } from '../../stores/UserStore'
import { DistributorStoreContext } from '../../stores/DistributorStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { initialPaginationConstants as initialPagination } from '../../utils/helpers'

// Utils, Services & Messages
import colors from '../../utils/colors'
import messages from './VCheckReviewPage.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { ClientAvatar } from '../../components/ClientAvatar'
import { getClientAvatarlUrl } from '../../services/agency.service'
import { Stack } from '../../components/Stack'

/**
 *
 * VCheckReviewPage
 *
 * This container holds all components needed for the Agent Home Page
 * On this page an agent can view top events for the clients they are
 * the primary agent for.
 *
 */

const sidebarInitialState = {
  id: null,
  completed: null,
  type: '',
  video: null,
  randomPhrase: [],
  accepted: null,
  reviewed_by: '',
  reviewed_on: '',
  notes: '',
  bac_result: '',
}

const options = [
  { value: 'All', label: 'All' },
  { value: 'vchecks', label: 'VChecks' },
  { value: 'bac_checks', label: 'BAC Checks' },
]

const clientsReducer = (state, action) => {
  if (action.type === 'reload') {
    return action.data
  }

  const result = { ...state }
  return result
}

let rowsPerPage = initialPagination.rows
let currentPage = initialPagination.pageNumber

const VCheckReviewPage = observer(() => {
  const agencyStore = useContext(AgencyStoreContext)
  const distributorStore = useContext(DistributorStoreContext)
  const { user, isDistributor, distributorId } = useContext(UserStoreContext)
  const clientWizardStore = useContext(ClientWizardStoreContext)

  const [agencyOptions, setAgencyOptions] = useReducer((state, agent) => [...state, agent], [])
  const [agencyName, setAgencyName] = useState('')
  const [selectedClientTypes, setselectedClientTypes] = useState('All')
  const [pageLoadError, showPageLoadError] = useState(null)
  const { message: error, showMessage: showError } = useFlashMessage(null)

  const [client, setClient] = useReducer(clientsReducer, {})
  const [clientVChecks, setClientVChecks] = useState([])

  const [agencyId, setAgencyId] = useState(!isDistributor ? user.agencyId : null)
  const [agenciesLoading, setAgenciesLoading] = useState(isDistributor)
  const [clientsLoading, setClientsLoading] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(false)
  const [selectedAgency, setSelectedAgency] = React.useState('')
  const [imageLoading, setImageLoading] = useState(false)
  const [clientAvatarUrl, setClientAvatarUrl] = useState(null)
  // const [selectedAgencyFilter, setSelectedAgencyFilter] = React.useState({ id: 0, name: 'All' })

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()
  const [sidebarState, dispatch] = useReducer(sidebarReducer, sidebarInitialState)
  const [reviewRadio, setReviewRadio] = useState(null)

  const convertToUserTimezone = time => time.tz(moment.tz.guess()).format('MM/DD/YY hh:mm A z')
  const convertToClientTimezone = (time, zone) => time.tz(zone).format('MM/DD/YY hh:mm A z')

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return sidebarInitialState
  }

  const [allAgenciesInDistributorWithIds, setAllAgenciesInDistributorWithIds] = useReducer(
    (state, agency) => [...state, agency],
    [],
  )

  useEffect(() => {
    const getAgencyList = async () => {
      const response = await distributorStore.getAgenciesByDistributorId(
        user.distributorId,
        showPageLoadError,
        setAgenciesLoading,
      )

      const agencies = response.results

      agencies.forEach(agency => {
        const displayName = agency.name
        setAgencyOptions(displayName)

        setAllAgenciesInDistributorWithIds({
          name: displayName,
          agencyId: agency.id,
        })
      })

      if (agencies.length > 0) {
        await setAgencyId(agencies[0].id)
        await setSelectedAgency(agencies[0].name)
      }
    }
    const getAgencyName = async () => {
      const agency = await agencyStore.getAgencyById(agencyId, showPageLoadError, setLoading)
      setAgencyName(agency.name)
    }
    if (!agencyId) {
      getAgencyList()
    } else {
      getAgencyName()
    }
  }, [])

  const getClientsVchecks = async () => {
    const response = await clientWizardStore.getClientVCheckEventsById(
      agencyId,
      null,
      showPageLoadError,
      setLoading,
      currentPage,
      rowsPerPage,
      true,
      selectedClientTypes,
    )
    if (response) {
      setRowCount(response.count)
    }
  }

  useEffect(() => {
    // Fetch all active clients
    const getActiveClients = async () => {
      if (!isDistributor) {
        await agencyStore.getClientsByAgency(
          agencyId,
          showPageLoadError,
          setClientsLoading,
          null,
          undefined,
          null,
          undefined,
        )
      } else {
        await distributorStore.getClientsBydistributorId(
          distributorId,
          showPageLoadError,
          setClientsLoading,
          null,
          undefined,
          null,
          undefined,
        )
      }
    }

    if (agencyId) {
      Promise.all([getActiveClients()])
    }
  }, [agencyId])

  // useEffect(() => {
  //   const activeClients = !isDistributor
  //     ? agencyStore.clients
  //     : distributorStore.distributorClients
  //   if (!loading && !clientsLoading && activeClients.length > 0) {
  //     const completedVchecks = clientWizardStore.clientCompletedVChecks.map(vcheckData => {
  //       const vcheckClient = activeClients.find(data => data.id === Number(vcheckData.client_id))

  //       if (vcheckClient) {
  //         const vcheckDetails = {
  //           clientTimezone: vcheckClient.timezone,
  //           client_name: `${vcheckClient.first_name} ${vcheckClient.last_name}`,
  //           ...vcheckData,
  //         }
  //         return vcheckDetails
  //       }

  //       return vcheckData
  //     })
  //     console.log('completedVchecks', completedVchecks)

  //     setClientVChecks(completedVchecks)
  //   }
  // }, [
  //   loading,
  //   clientWizardStore.clientCompletedVChecks,
  //   distributorStore.distributorClients,
  //   agencyStore.clients,
  // ])



  useEffect(() => {
    if (selectedClientTypes && selectedClientTypes !== 'temp') {
      console.log('selectedClientTypes', selectedClientTypes)
      const activeClients = !isDistributor
        ? agencyStore.clients
        : distributorStore.distributorClients
      if (!loading && !clientsLoading && activeClients.length > 0) {
        const fetchData = async () => {
          if (selectedClientTypes) {
            await getClientsVchecks()
          }
        }
        fetchData().then(() => {
          const completedVchecks = clientWizardStore.clientCompletedVChecks.map(vcheckData => {
            const vcheckClient = activeClients.find(data => data.id === Number(vcheckData.client_id))
            const vcheckDetails = {
              clientTimezone: vcheckClient.timezone,
              client_name: `${vcheckClient.first_name} ${vcheckClient.last_name}`,
              ...vcheckData,
            }
            return vcheckDetails
          })
          setClientVChecks(completedVchecks)
        })

        // const completedVchecks = clientWizardStore.clientCompletedVChecks.map(vcheckData => {
        //   const vcheckClient = activeClients.find(data => data.id === Number(vcheckData.client_id))

        //   if (vcheckClient) {
        //     const vcheckDetails = {
        //       clientTimezone: vcheckClient.timezone,
        //       client_name: `${vcheckClient.first_name} ${vcheckClient.last_name}`,
        //       ...vcheckData,
        //     }
        //     return vcheckDetails
        //   }

        //   return vcheckData
        // })
        // console.log('completedVchecks', completedVchecks)

        // setClientVChecks(completedVchecks)
      }
    }
  }, [selectedClientTypes])

  const handlePagination = _throttle(async () => {
    const response = await clientWizardStore.getClientVCheckEventsById(
      agencyId,
      null,
      showPageLoadError,
      setLoading,
      currentPage,
      rowsPerPage,
      true,
      selectedClientTypes,
    )
    if (response) {
      setRowCount(response.count)
    }
  }, 500)

  const renderClientAvatar = async clientId => {
    await getClientAvatarlUrl(
      agencyId,
      clientId,
      'gcp_profile_bucket',
      showPageLoadError,
      setImageLoading,
    )
      .then(clavt => {
        setClientAvatarUrl(clavt)
      })
      .catch(() => {
        showPageLoadError(false)
        setClientAvatarUrl(null)
      })
  }

  const handleRowsPerPageChange = async perPage => {
    rowsPerPage = perPage
    handlePagination()
  }

  const handlePageChange = async page => {
    currentPage = page
    handlePagination()
  }

  const onClose = () => {
    // clear siderbar form state
    dispatch({ type: 'reset' })
    setReviewRadio(null)
    setShowSidebar(false)
    showError(false)
  }
  const openModal = () => {
    setShowSidebar(true)
  }

  const getVideoData = async vcheck => {
    const response = await clientWizardStore.getClientVCheckVideo(
      agencyId,
      vcheck.client_id,
      vcheck.file,
      showError,
      setVideoLoading,
    )
    dispatch({
      fieldName: 'video',
      data: response.video_url || null,
    })
  }

  // Function to pass into VCheckTable that
  // will open and populate sidebar
  const editInSidebar = vcheck => {
    getVideoData(vcheck)
    renderClientAvatar(vcheck.client_id)
    let completedTime
    if (vcheck.completed) {
      completedTime = convertToClientTimezone(moment(vcheck.completed), vcheck.clientTimezone)
    }
    setClient({
      type: 'reload',
      data: {
        id: vcheck.client_id,
        name: vcheck.client_name,
      },
    })
    dispatch({
      type: 'update',
      data: {
        id: vcheck.document_id,
        completed: completedTime,
        type: vcheck.type === 'bac_checks' ? 'BAC Check' : 'VCheck',
        client_type: vcheck.type,
        video: vcheck.video,
        file: vcheck.file,
        randomPhrase: vcheck.utterances || [],
        accepted: vcheck.accepted,
        reviewed_by: vcheck.reviewed_by
          ? vcheck.reviewed_by.user.first_name.concat(' ', vcheck.reviewed_by.user.last_name)
          : null,
        reviewed_on: vcheck.reviewed_on ? convertToUserTimezone(moment(vcheck.reviewed_on)) : null,
        notes: vcheck.notes,
        bac_result:
          (vcheck.type === 'bac_checks' && parseFloat(vcheck.bac_result).toFixed(3)) || false,
      },
    })

    openModal()
  }

  const isFormValid = reviewRadio != null

  // Sidebar submit function
  const onSubmit = async () => {
    const vcheckReviewToPost = {
      accepted: reviewRadio,
      notes: sidebarState.notes || '',
      DocumentId: sidebarState.id,
      client_type: sidebarState.client_type,
    }
    const updatedVCheckList = await clientWizardStore.updateClientVCheckEventById(
      agencyId,
      client.id,
      sidebarState.id,
      vcheckReviewToPost,
      showError,
      setLoading,
    )

    if (updatedVCheckList) {
      setselectedClientTypes('temp') // set to temp to trigger useEffect
      setTimeout(() => {
        setselectedClientTypes(selectedClientTypes);
      }, 0);
      onClose()
    }
  }

  const resetPage = () => {
    rowsPerPage = initialPagination.rows
    currentPage = initialPagination.pageNumber
  }

  const handleSelectedAgency = option => {
    const selectedAgencyOption = allAgenciesInDistributorWithIds.find(
      item => item.name === option.value,
    )
    resetPage()
    if (selectedAgency !== option.value) {
      setClientVChecks([])
    }
    setSelectedAgency(option.value)
    // setSelectedAgencyFilter(selectedAgencyOption)
    setAgencyId(selectedAgencyOption.agencyId)
  }

  // Build Sidebar header & content
  const sideBarHeader = client.name && (
    <Text capitalize>{`${sidebarState.type} - ${client.name}`}</Text>
  )

  const sidebarContent = (
    <>
      <Grid
        responsive
        rows={['medium', 'auto', 'auto', 'auto', 'auto']}
        columns={['auto']}
        gap="small"
        areas={[['video'], ['phrase'], ['review'], ['notes'], ['errorMessage']]}
      >
        <Box gridArea="video">
          <Header mini level="3" margin={{ top: 'none' }}>
            {`Completed: ${sidebarState.completed}`}
          </Header>

          {sidebarState.video && sidebarState.file && !videoLoading ? (
            <Box fill>
              <video controls height="100%">
                <source src={sidebarState.video} />
              </video>
            </Box>
          ) : (
            <Box fill justify="center" align="center">
              <Text size="small">Video processing. Please check back later.</Text>
            </Box>
          )}
        </Box>
        {sidebarState.video && (
          <>
            <Box gridArea="phrase">
              <Header mini level="3" margin={{ top: 'none' }}>
                {sidebarState.bac_result ? (
                  <FormattedMessage {...messages.bacHeader} />
                ) : (
                  <FormattedMessage {...messages.phraseHeader} />
                )}
              </Header>
              <Stack anchor="right" guidingChild="first">
                <Box direction="row">
                  {sidebarState.bac_result ? (
                    <>

                      <Text size="small" color="black" style={{ height: '42px' }}>
                        {sidebarState.bac_result}
                      </Text>
                    </>) : (
                    <>
                      <StyledBox
                        pad="small"
                        justify="center"
                        align="center"
                        elevation="small"
                        background={colors.teal80}
                      >
                        <Text size="small" color="white">
                          {sidebarState.randomPhrase[0]}
                        </Text>
                      </StyledBox>

                      <StyledBox
                        pad="small"
                        justify="center"
                        align="center"
                        elevation="small"
                        background={colors.teal80}
                      >
                        <Text size="small" color="white">
                          {sidebarState.randomPhrase[1]}
                        </Text>
                      </StyledBox>
                    </>
                  )}
                </Box>
                <Box
                  direction="row-responsive"
                  justify="end"
                  align="center"
                  gap="small"
                  style={{ marginTop: '25px' }}
                >
                  <Box
                    background="light-2"
                    justify="center"
                    align="center"
                    pad="none"
                    round="full"
                    height="150px"
                    width="150px"
                  >
                    <ClientAvatar
                      file={clientAvatarUrl ? clientAvatarUrl.avatar_url : null}
                      type={clientAvatarUrl ? clientAvatarUrl.type : null}
                      loading={imageLoading}
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>

            <Box gridArea="review" direction="column">
              <Header mini level="3" margin={{ top: 'none' }}>
                <FormattedMessage {...messages.reviewHeader} />
              </Header>

              {sidebarState.accepted != null ? (
                <Text size="small">{`${sidebarState.accepted ? 'Approved' : 'Rejected'} by ${sidebarState.reviewed_by
                  } on ${sidebarState.reviewed_on}`}</Text>
              ) : (
                <Box direction="row" gap="medium">
                  <StyledBox
                    pad="small"
                    justify="center"
                    align="center"
                    elevation={reviewRadio === true ? 'none' : 'small'}
                    background={reviewRadio === true ? colors.primary : 'transparent'}
                    onClick={() => {
                      setReviewRadio(reviewRadio === true ? null : true)
                    }}
                  >
                    <Text size="small" color={reviewRadio === true ? 'white' : colors.primaryText}>
                      Approve
                    </Text>
                  </StyledBox>

                  <StyledBox
                    pad="small"
                    justify="center"
                    align="center"
                    elevation={reviewRadio === false ? 'none' : 'small'}
                    background={reviewRadio === false ? colors.primary : 'transparent'}
                    onClick={() => {
                      setReviewRadio(reviewRadio === false ? null : false)
                    }}
                  >
                    <Text
                      size="small"
                      color={reviewRadio === false ? 'white' : colors.primaryText}
                    >
                      Reject
                    </Text>
                  </StyledBox>
                </Box>
              )}
            </Box>

            <Box gridArea="notes" direction="column">
              <Header mini level="3" margin={{ top: 'none' }}>
                <FormattedMessage {...messages.notesHeader} />
              </Header>

              {sidebarState.accepted != null ? (
                <TextBlock multiLineString={sidebarState.notes} />
              ) : (
                <TextArea
                  size="small"
                  id="notes"
                  value={sidebarState.notes}
                  onChange={e =>
                    dispatch({
                      fieldName: 'notes',
                      data: e.target.value,
                    })
                  }
                ></TextArea>
              )}
            </Box>
          </>
        )}
        <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>
      </Grid>
    </>
  )

  if (agenciesLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }

  return (
    <>
      <GrommetHeader
        style={{
          background: '#f9f9f9',
          boxShadow: 'inset 0 -1px 0 0 #d9d9d9',
        }}
        pad={{ horizontal: 'medium' }}
      >
        <Grid responsive>
          {isDistributor ? (
            <Box direction="row" pad="medium">
              <Box
                pad={{
                  horizontal: 'small',
                  vertical: 'small',
                }}
                style={{ fontSize: 14 }}
              >
                Filter By:
              </Box>

              <Box pad={{ horizontal: 'medium' }} direction="column">
                <Box style={{ fontSize: 14 }} pad={{ vertical: 'small' }}>
                  Agency Name
                </Box>
                <Select
                  style={{ padding: '5px', fontSize: 14 }}
                  size="small"
                  name="filter_by_agent"
                  id="filter_by_agent"
                  options={agencyOptions}
                  value={selectedAgency}
                  onChange={handleSelectedAgency}
                  searchPlaceholder="Agent"
                  placeholder="No matches found"
                ></Select>
              </Box>
            </Box>
          ) : (
            <Box direction="row" pad="medium" gap="medium">
              <Box style={{ fontSize: 14 }} pad={{ vertical: 'small' }}>
                {`${agencyName} - Top Events`}
              </Box>
              <Select
                style={{ padding: '5px', fontSize: 14 }}
                size="small"
                name="filter_by_client_types"
                id="filter_by_client_types"
                options={options}
                labelKey="label"
                valueKey="value"
                value={options.find(option => option.value === selectedClientTypes)}
                onChange={({ option }) => {
                  setselectedClientTypes(option.value)
                }}
                searchPlaceholder="Client Types"
                placeholder="No matches found"
              ></Select>
            </Box>
          )}
        </Grid>
      </GrommetHeader>

      <Grid
        responsive
        rows={['auto']}
        columns={['auto']}
        gap="small"
        pad={{ horizontal: 'medium' }}
        areas={[['table']]}
      >
        <Box pad="medium" fill gridArea="table">
          <VCheckReviewTable
            clientVChecks={clientVChecks}
            agencyName={selectedAgency}
            rowCount={rowCount}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handlePageChange={handlePageChange}
            editInSidebar={editInSidebar}
            loading={loading}
            isDistributorUser={isDistributor}
          />
        </Box>
      </Grid>
      {!!showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          isFormValid={isFormValid}
          onSubmit={onSubmit}
          header={sideBarHeader}
          content={sidebarContent}
        ></WizardSidebar>
      )}
    </>
  )
})

const StyledBox = styled(Box)`
  border: 1px solid #f2f2f2;
  min-width: 100px;
`

export default injectIntl(VCheckReviewPage)

VCheckReviewPage.propTypes = {
  intl: intlShape.isRequired,
}
