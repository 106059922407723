import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

// Components
import { PrivateRoute } from 'components/PrivateRoute'
import { PublicRoute } from 'components/PublicRoute'

// Containers
import DashboardPage from 'containers/DashboardPage/DashboardPage.loadable'
import { VCheckReviewPage } from 'containers/VCheckReviewPage'
import AgentPage from 'containers/AgentPage/AgentPage.loadable'
import AgencyInfoPage from 'containers/AgencyInfoPage/AgencyInfoPage.loadable'
import ClientPage from 'containers/ClientPage/ClientPage.loadable'
import { ClientMonitoringPage } from 'containers/ClientMonitoringPage'
import { ClientMessageLogPage } from 'containers/ClientMessageLogPage'
import { ClientReportsPage } from 'containers/ClientReportsPage'
import { ClientSummaryPage } from 'containers/ClientSummaryPage'
import { ClientVCheckPage } from 'containers/ClientVCheckPage'
import ClientWizard from 'containers/ClientWizard/ClientWizard'
import ReportPage from 'containers/ReportPage/ReportPage.loadable'
import { ConfirmAccount } from 'containers/ConfirmAccount'
import { Login } from 'containers/Login'
import { NotFoundPage } from 'containers/NotFoundPage'
import { PasswordReset } from 'containers/PasswordReset'
import { PasswordResetRequest } from 'containers/PasswordResetRequest'
import { ZonesPage } from 'containers/ZonesPage'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Styles
import GlobalStyle from '../../global-styles'
import { ProfileSidebar } from '../../components/ProfileSidebar'

// Utils
import { flags } from '../../utils/flags'

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
export default function App() {
  const { user } = useContext(UserStoreContext)

  // Load Google Places script
  useEffect(() => {
    const googlePlacesScriptTag = document.createElement('script')
    googlePlacesScriptTag.type = 'text/javascript'
    googlePlacesScriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`
    document.querySelector('head').insertAdjacentElement('beforeend', googlePlacesScriptTag)

    if (user.accessToken) {
      Sentry.configureScope(scope => {
        scope.setUser({
          ...user,
        })
      })
    } else {
      Sentry.configureScope(scope => scope.setUser(null))
    }
  }, [])

  const location = useLocation()
  const background = location.state && location.state.background

  return (
    <>
      <Helmet titleTemplate="VCheck24" defaultTitle="VCheck24">
        <meta name="description" content="VCheck24 Agent Portal" />
      </Helmet>

      <>
        <Switch location={background || location}>
          {/** @fixme replace with LandingPage as a Route */}
          <PublicRoute exact path="/" component={Login} />

          {/* - Auth and registration */}
          <Route path="/confirm-account" component={ConfirmAccount} />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/password-reset-request" component={PasswordResetRequest} />
          {/* -- PasswordReset is also used for setting up a new agent - this displays an error if the user is authenticated */}
          <Route exact path="/password-reset" component={PasswordReset} />

          {/** We must use the flag object directly, otherwise Switch will not traverse child
           * components, which causes the app to break
           */}

          {/* - Dashboard */}
          <PrivateRoute path="/dashboard" component={DashboardPage} />

          {/* - VCheck Review */}
          <PrivateRoute path="/checkinreview" component={VCheckReviewPage} />

          {/* -Agency Info */}
          <PrivateRoute path="/agencyinfo" component={AgencyInfoPage} />

          {/* - Agents */}
          <PrivateRoute path="/agents" component={AgentPage} />

          {/* - Clients */}
          <PrivateRoute exact path="/clients" component={ClientPage} />
          <PrivateRoute path="/clients/wizard" component={ClientWizard} />
          <PrivateRoute path="/clients/summary" component={ClientSummaryPage} />
          <PrivateRoute path="/clients/location-tracking" component={ClientMonitoringPage} />
          <PrivateRoute path="/clients/message-log" component={ClientMessageLogPage} />
          <PrivateRoute path="/clients/vchecks" component={ClientVCheckPage} />
          <PrivateRoute path="/clients/reports" component={ClientReportsPage} />

          {/* - ZonesPage */}
          <PrivateRoute exact path="/zones" component={ZonesPage} />

          {/* - Reports */}
          {/** We must use the flag object directly, otherwise Switch will not traverse child
           * components, which causes the app to break
           */}
          {flags.pages.reports === true && <PrivateRoute path="/report" component={ReportPage} />}

          {/* - Profile */}
          <PrivateRoute path="/profile" component={ProfileSidebar} />

          {/* - Catch-all / Not Found */}
          <Route path="" component={NotFoundPage} />
        </Switch>

        {/**
         * Profile is essentially a partial view as a sidebar, so we define it outside of
         * the Switch in order to match multiple components (the background component, such as
         * `/clients` and the profile component itself)
         */}
        {background && (
          <PrivateRoute path="/profile" component={ProfileSidebar} key={Date.now()} />
        )}
      </>
      <GlobalStyle />
    </>
  )
}
